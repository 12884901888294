<template>
	<div id="mobile-footer-accordion" class="col-12 px-0 mobile">
		<accordion-footer
			:scroll="true"
			class="m-top-first"
			:name="column.columntitle.replace(' ', '-')"
			v-for="(column, j) in sectionData['leftcolumns']"
			:transitionTime="'0.7'"
			:key="j"
			:secondary="true"
			:flipMode="true"
			:title="column.columntitle"
			:chevronImage="'/assets/icons/orange/chevron_down.svg'"
		>
			<template #content>
				<ul class="pb-1">
					<li v-for="(line, i) in column.links" :key="i" class="pb-2">
						<a :href="line.url" class="t-capitalize t-white">{{ line.display }}</a>
					</li>
				</ul>
			</template>
		</accordion-footer>
	</div>
</template>

<script>
import AccordionFooter from '../utils/accordion/accordionFooter.vue'
import * as yaml from 'js-yaml'
import * as fs from 'fs'
export default {
	name: 'FooterMobile',
	components: {
		AccordionFooter,
	},
	data() {
		return {
			sectionData: {},
			currentOpened: null,
		}
	},
	mounted() {
		try {
			const fileContents = fs.readFileSync('_data/footer.yml', 'utf8')
			this.sectionData = yaml.safeLoad(fileContents)
		} catch (e) {
			console.log('error fething cms info', e)
		}
	},
	methods: {
		clickFunction(id) {
			if (this.currentOpened === id) this.currentOpened = null
			else {
				if (this.currentOpened != null) {
					var button = document.getElementById(
						'button-' +
							this.sectionData.leftcolumns[this.currentOpened].columntitle.replace(
								' ',
								'-'
							)
					)
					button && button.click()
				}
				this.currentOpened = id
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../../assets/sass/inc/default/grid/grid-vue-mixins.scss';

#mobile-footer-accordion {
	margin-bottom: 20px;
}
#mobile-footer-accordion .accordion .card .card-content div ul {
	margin: 0;
}
#mobile-footer-accordion .accordion .card .card-content div ul li {
	margin-bottom: 12px;
}
#mobile-footer-accordion .accordion .card .card-content div ul li a {
	color: white;
}
#mobile-footer-accordion .accordion .card {
	background-color: #1d4045;
	border-top: none;
	border-bottom: solid 1px grey;
}
#mobile-footer-accordion .accordion .card .card-header .card-header-icon svg > * {
	fill: white !important;
}
#mobile-footer-accordion
	.accordion
	.card.card-active
	.card-header
	.card-header-icon
	svg
	> * {
	fill: #ffaa2b !important;
}
#mobile-footer-accordion {
	.panel.slide {
		max-height: 500px;
		transition: all 1s ease-in;
	}
	.panel {
		padding: 0;
		background-color: #1d4045;
		max-height: 0;
		transition: all 1s ease-in-out;
		-webkit-transition: all 1s ease-in-out;
		-moz-transition: all 1s ease-in-out;
		-ms-transition: all 1s ease-in-out;
		-o-transition: all 1s ease-in-out;
		overflow: hidden;

		ul {
			margin-bottom: 15px;
			margin-top: 20px;

			li {
				a {
					display: block;
					color: $white;
					text-transform: capitalize;
					font-size: 14px;
					letter-spacing: 0.5px;
					padding-bottom: 5px;

          @include target-md{
  					font-size: 18px;
          }

					&:hover {
						@include transform(translateX(10px));
					}

					&:hover,
					&:focus,
					&:active,
					&.active {
						color: $brand-primary;
					}
				}
			}
		}
	}
	.accordion-row {
		color: white;
		cursor: pointer;
		padding: 20px 0px;
		border: none;
		text-align: left;
		outline: none;
		font-weight: bold;

		&.line-secondary {
			border-top: none;
			border-bottom: 1px solid $brand-secondary-light-blue;
		}
	}

	.accordion-arrow {
		top: 27px;
		width: 18px;

		@include target-md {
			top: 31px;
			width: 33px;
		}
	}

	.accordion {
		button {
			p {
				line-height: 100%;
			}
		}
		border: none;
	}
}
</style>
